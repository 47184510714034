@import "~@styles/variables";

#root {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .home {
    flex: 1;
  }
}

#__assaia_ui_portal {
  z-index: 2;
}

// Prevent content bouncing in iOS
html,
body {
  position: fixed;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-font-smoothing: antialiased;
}
