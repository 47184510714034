@use "~@frontend/airfoil/dist/scss/color-themes.scss";

// Pure
@import "constants";
@import "mixins";

// This is font awesome only
@import "fonts";

// Cherry-pick only necessary styles from assaia-ui
@import "~@frontend/assaia-ui/dist/styles/variables/color-primitives.scss";
@import "~@frontend/assaia-ui/dist/styles/variables/color-semantic.scss";
@import "~@frontend/assaia-ui/dist/styles/variables/size-primitives.scss";

// assaia-ui lib component styles
@import url("~@frontend/assaia-ui/dist/index.esm.css");

// TODO: Take it from airfoil
:root {
  font-size: 12px;
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;

  @include color-themes.dark-theme;
}

body,
html {
  margin: 0;
  padding: 0;
  color: #fff;
  -webkit-text-size-adjust: 100%;
  overscroll-behavior: none;
  @include background-gradient;
}

h1.exception {
  margin-top: 150px;
  color: white;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 1px;
}

a {
  cursor: pointer;
}

.assaia-ui-portal {
  position: fixed;
  inset: 0;
  pointer-events: none;
}
