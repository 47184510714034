.errorMessage {
  text-align: center;
  color: #828f99;
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-self: center;
  justify-self: center;
  margin: auto;
  gap: 32px;
  padding: 32px 16px;
}

.towerIcon {
  height: 48px;
  width: fit-content;
  align-self: center;
}

.title {
  font-weight: 300;
  font-size: 32px;
}

.refreshBtn {
  width: fit-content;
  align-self: center;
}
