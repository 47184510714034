@import "./constants";

@mixin grid-cords($row, $col) {
  grid-column: $col;
  grid-row: $row;
  -ms-grid-column: $col;
  -ms-grid-row: $row;
}

@mixin media($key, $type: "width") {
  @if not map-has-key($breakpoints, $type) {
    @error "Error at mixin 'media': Unknown argument $type '#{$type}'. Possible values are #{map-keys($breakpoints)}";
  }

  $scope: map-get($breakpoints, $type);

  @if not map-has-key($scope, $key) {
    @error "Unknown mixin 'media' parameter $key '#{$key}'. Possible values are #{map-keys($scope)}";
  }

  @media screen and (max-width: map-get($scope, $key)) {
    @content;
  }
}

@mixin layout-padding($withoutMedia: false) {
  padding-right: $page-inner-h-padding;
  padding-left: $page-inner-h-padding;

  @if $withoutMedia != true {
    @include media(tablet) {
      padding-right: $page-inner-h-padding-md;
      padding-left: $page-inner-h-padding-md;
    }
  }
}

@mixin background-gradient {
  background: linear-gradient(
      252deg,
      rgba(154, 172, 187, 0.1) 0%,
      rgba(154, 172, 187, 0) 100%
    ),
    var(--colour-bg-ui-1, #18222a);
  background-attachment: fixed;
}
